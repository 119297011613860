    import config from "../config";


    export default {
        data () {
            return {
            }
        },
        methods: {

            /* OPEN A LOCALISED PDF FILE (ie: "conditions_fr_be.pdf") */
            openPDFFile (name='privacy'){
                let link = config.assetPath + "files/" + name + "_";
                window.open(link + this.$i18n.locale.toLowerCase() +".pdf");
            },

        },
    }