<template>
	<div>
		Lorem
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:""
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	
</style>
